<template>
  <CompositionForm
    @save="updateComposition"
    :type="'edit'"
    :composition="composition ? composition : getCurrentComposition()"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CompositionForm: () => import('./CompositionForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    composition: {},
    askToLeave: true,
  }),
  methods: {
    getCurrentComposition() {
      this.$api
        .get(`product_compositions/${this.$route.params.id}`)
        .then((res) => {
          this.composition = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateComposition() {
      this.askToLeave = false
      const formData = new FormData()
      const compositionKeys = Object.keys(this.composition)
      const compositionValues = Object.values(this.composition)

      for (let i = 0; i < compositionKeys.length; i++) {
        if (compositionValues[i] !== null) {
          formData.append(compositionKeys[i], compositionValues[i])
        }
      }
      this.$api
        .post(`product_compositions/${this.$route.params.id}`, formData)
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Gravação atualizado com sucesso',
            confirm: () => {
              this.$router.push({ name: 'composition' })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha ao tentar atualizar este gravação',
            caption: 'Verifique se os campos estão preenchidos corretamente.',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  created() {
    this.getCurrentComposition()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
